
import { Component, Mixins } from 'vue-property-decorator';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';

import TestimonialList from '../components/Testimonials/TestimonialList.vue';

@Component({
  components: {
    TestimonialList,
  },
})
export default class Testimonials extends Mixins(View) {
    private filter: TestimonialFilter = new TestimonialFilter();
}
